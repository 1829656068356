<template>
  <en-table-layout :stripe="false" :tableData="tableData.data" :loading="loading" ref="multipleTable"
    @selection-change="handleSelectionChange">
    <template slot="toolbar">
      <div class="col">
        <div class="toolbar-title">
          操作日志
        </div>
      </div>
      <div class="col-auto">
        <!--        <el-button size="small" @click="GET_AccountLog">刷新</el-button>-->
        <!-- <el-button @click="handleRemove" size="small" type="primary">批量删除</el-button> -->
      </div>
    </template>

    <template slot="table-columns">
      <!-- 编号 -->
      <el-table-column align="center" type="selection" width="55" />
      <!-- 操作者 -->
      <el-table-column prop="account_name" label="操作者" />
      <!-- 操作日期 -->
      <el-table-column label="操作日期">
        <template slot-scope="scope">{{ scope.row.create_date | unixToDate }}</template>
      </el-table-column>
      <!-- IP地址 -->
      <el-table-column prop="ip" label="IP地址" />
      <!-- 操作记录 -->
      <el-table-column prop="oper_detail" label="操作记录" width="350" />
    </template>

    <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
      :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="tableData.data_total">
    </el-pagination>
  </en-table-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import * as API_SiteMenu from '@/api/siteMenu';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'loginJournal',
  components: { EnTableLayout },
  computed: {
    ...mapGetters(['user'])
  },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表数据 */
      tableData: {},

      /** 分页 */
      page_no: 1,
      page_size: 20,
      data_total: '',

      /** 选中数据 */
      multipleSelection: [],
      idsList: [],
    }
  },
  mounted () {
    this.GET_AccountLog();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.page_size = size
      this.GET_AccountLog()
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.page_no = page
      this.GET_AccountLog()
    },
    handleSelectionChange (val) {
      let ids = []
      console.log(val)
      val.forEach(item => {
        ids.push(item.id)
      })
      this.idsList = ids
      console.log(this.idsList)
    },
    /** 获取操作日志列表 */
    GET_AccountLog () {
      this.loading = true;
      /** 列表参数 */
      let params = {
        page_no: this.page_no,
        page_size: this.page_size,
        account_id: this.user.uid,   // 账户id
        account_type: 3, // 账户类型 0:前端会员 1:商家 2：供应商 3：企业，4大平台
        type: 2,         // 日志类型，1登录日志，2操作日志，3其他日志
      };
      API_SiteMenu.getAccountLog(params).then(res => {
        this.loading = false;
        console.log(res, 'ressssssssssg')
        this.tableData = res;
      }).catch(() => {
        this.loading = false
      })
    },
    //批量删除
    handleRemove () {
      if (!this.idsList.length) {
        this.$message.error('条要删除的信息')
      } else {

        this.$confirm('确认删除这些信息, 是否继续?', '提示', { type: 'warning' }).then(() => {
          API_SiteMenu.deleteBatch(this.idsList.toString()).then(response => {
            this.$message.success('删除成功！')
            this.GET_AccountLog();
          }).catch(() => {
            this.loading = false
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
